import PL_img_1 from "../../../assets/productListImages/PL_img_1.png";
import PL_img_2 from "../../../assets/productListImages/PL_img_2.png";
import PL_img_3 from "../../../assets/productListImages/PL_img_3.png";
import PL_img_4 from "../../../assets/productListImages/PL_img_4.png";
import PL_img_5 from "../../../assets/productListImages/PL_img_5.png";
import PL_img_6 from "../../../assets/productListImages/PL_img_6.png";


export const categoriesList = [
    {id: 1, title: "Industrial Machinery"},
    {id: 2, title: "Safety Supplies"},
    {id: 3, title: "Oil & Gas"},
    {id: 4, title: "Electrical Equipments"},
    {id: 5, title: "Measurements & Testing"},
    {id: 6, title: "Home Appliances"},
    {id: 7, title: "Packaging"}
]

export const productListData = [
    {
      id: 1,
      imgSrc: PL_img_1,
      title: "3M",
      description: "Hat 4-Point Ratchet Suspension Helmet"
    },
    {
      id: 2,
      imgSrc: PL_img_2,
      title: "KARAM",
      description: "PN521 Safety Helmet, White"
    },
    {
      id: 3,
      imgSrc: PL_img_3,
      title: "KARAM",
      description: "Hear Muff - High dB"
    },
    {
      id: 4,
      imgSrc: PL_img_4,
      title: "Zain Safety",
      description: "SR 103 - Black"
    },
    {
      id: 5,
      imgSrc: PL_img_5,
      title: "3M",
      description: "2890 Safety Googles"
    },
    {
      id: 6,
      imgSrc: PL_img_6,
      title: "3M",
      description: "Ratchet Headgear H8A"
    }
  ];