import AboutUsComponent from "../aboutUs/aboutUs.component";

import servicesImage_one from "../../assets/servicesImage/servicesImage_one.png";
import servicesImage_two from "../../assets/servicesImage/servicesImage_two.png";
import servicesImage_three from "../../assets/servicesImage/servicesImage_three.png";

import "./services.style.scss";

const ServicesComponent = () => {
  return (
    <>
        <div className="Services_container">
            <div className="Text_wrapper">
                <div className="Heading">
                    <span class="material-symbols-outlined Icon">remove</span><span>Our Services</span>
                </div>
                <h1 className="Sub_text">From Concept To Completion</h1>
            </div>
            <div className="Services_wrapper">
                <div className="Image_container One">
                <img src={servicesImage_one} alt="servicesImage_one.png" loading="lazy" />
                <p className="Heading_one">Product Consultation</p>
                <p className="Long_text">
                    Offering expert advice on selecting the right products for specific
                    projects or applications
                </p>
                </div>
                <div className="Image_container Two">
                <img src={servicesImage_two} alt="servicesImage_two.png" loading="lazy" />
                <p className="Heading_one">Supply Chain Solutions</p>
                <p className="Long_text">
                    Providing supply chain solutions to ensure timely delivery of products to clients.
                </p>
                </div>
                <div className="Image_container Three">
                <img src={servicesImage_three} alt="servicesImage_three.png" loading="lazy" />
                <p className="Heading_one">Recraft Building Material Sourcing</p>
                <p className="Long_text">
                    Assisting clients in sourcing high-quality recraft building materials for their projects.
                </p>
                </div>
            </div>
        </div>
        <AboutUsComponent />
    </>
  );
};

export default ServicesComponent;
