import aboutUs from "../../assets/aboutUs.png";
import FBIcon from "../../assets/social/FB.png";
import LinkedInIcon from "../../assets/social/linkedIn.png";
import InstagramIcon from "../../assets/social/Instagram.png"
import BrandCarouselComponent from "../brandCarousel/brandCarousel.component";

import "./aboutUs.style.scss";
import SafetyAdComponent from "../safetyAd/safetyAd.component";

const AboutUsComponent = () => {
  return (
    <>
      <div className="AboutUs_container">
        <div className="Left_side_container">
          <img src={aboutUs} alt="aboutUs.png" />
        </div>
        <div className="Right_side_container">
          <div className="Heading">
            <span class="material-symbols-outlined Icon">remove</span>
            <span>About Us</span>
          </div>
          <p className="Sub_heading">
            Quality, Integrity, Expertise: The Fantom Difference
          </p>
          <p className="Long_text">
            We are a trusted name in the industrial goods and construction
            material sector, serving projects across the country.{" "}
            <b>
              Our commitment to quality and innovation has made us a go-to
              choice for large-scale industrial developments and recraft
              building initiatives.
            </b>{" "}
            We take pride in delivering excellence, offering our customers the
            tools and materials they need to bring their visions to life.
          </p>
          <p className="Sub_long_text">
            With a dedicated team and passion for what we do, we continue to
            expand our reach and impact, aiming to be the premier choice for
            industrial solutions.
          </p>
          <div className="Social_wrapper">
            <p className="Title">Follow us:</p>
            <div className="Image_wrapper">
              <img src={FBIcon} alt="FBIcon.png" />
              <img src={InstagramIcon} className="Instagram_logo" alt="InstagramIcon.png" />
              <img src={LinkedInIcon} alt="LinkedInIcon.png" />
            </div>
          </div>
        </div>
      </div>
      <BrandCarouselComponent />
      <SafetyAdComponent />
    </>
  );
};

export default AboutUsComponent;
