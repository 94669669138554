import FBIcon from "../../assets/footerSocial/fb.png";
import LinkedInIcon from "../../assets/footerSocial/LinkedIn.png";
import InstagramIcon from "../../assets/footerSocial/insta.png";
import { whatsAppIcon } from "../../utils/svg";

import "./footer.style.scss";

const FooterComponent = () => {
  return (
    <footer className="Footer_container">
      <div className="Left_side">
        <h2 className="Heading">
          Need assistance with larger order? Feel free to{" "}
          <span className="Inner_text">get in touch</span> with us!
        </h2>
        <div className="Social_wrapper">
          <p className="Text">Follow us:</p>
          <div className="Image_wrapper">
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=61559868220545&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FBIcon} alt="FBIcon.png" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/enterprisesfantom/?igsh=MWN1eDJua3Fkdmticw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="Instagram_img"
                  src={InstagramIcon}
                  alt="InstagramIcon.png"
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/in/fantom-enterprises-257b1a2b6?original_referer="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedInIcon} alt="LinkedInIcon.png" />
              </a>
            </div>

          </div>
        </div>
      </div>
      <div className="Right_side">
        <p className="Reach_text">Reach us at:</p>

        <ul className="React_us_wrapper">
          <li className="Ul_list">Corporate Address</li>
          <div className="Corporate_address">
            229/S1, 3rd Cross Brindavan extension, Mico Layout, Arekere,
            Bengaluru - 560078
          </div>
          <li className="Ul_list">Branch Office</li>
          <div className="Branch_address">
            1045/A/3, Plot No. 05, Ujwal Nagar, Belagavi - 590016
          </div>
        </ul>

        <div className="Connect_us_wrapper">
          <p className="Connect_text">Connect with us:</p>
          <div className="Connect_info">
            <span class="material-symbols-outlined Icon">mail</span>
            <span className="Mail">info.fantom1@gmail.com, contact@fantomenterprises.com</span>
          </div>
          <div className="Connect_info">
            <span class="material-symbols-outlined Icon">phone_in_talk</span>
            <span className="Phone_number">+91 9380582067</span>{" "}
            <span className="Line" />
            <span className="Phone_number">+91 7975352621</span>
          </div>
          <div className="WhatsApp_container">
            <a
              className="WhatsApp_icon"
              href="https://web.whatsapp.com/send/?phone=+919380582067"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="Icon">{whatsAppIcon()}</i>
              Message us on WhastApp
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
