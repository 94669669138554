import brand_img_1 from "../../../assets/brandImages/brand_img_1.png";
import brand_img_2 from "../../../assets/brandImages/brand_img_2.png";
import brand_img_3 from "../../../assets/brandImages/brand_img_3.png";
import brand_img_4 from "../../../assets/brandImages/brand_img_4.png";
import brand_img_5 from "../../../assets/brandImages/brand_img_5.png";
import brand_img_6 from "../../../assets/brandImages/brand_img_6.png";
import brand_img_7 from "../../../assets/brandImages/brand_img_7.png";
import brand_img_8 from "../../../assets/brandImages/brand_img_8.png";
import brand_img_9 from "../../../assets/brandImages/brand_img_9.png";
import brand_img_10 from "../../../assets/brandImages/brand_img_10.png";

export const brandBannerData = [
    {
        id: 1,
        imgSrc: brand_img_1
    },
    {
        id: 2,
        imgSrc: brand_img_2
    },
    {
        id: 3,
        imgSrc: brand_img_3
    },
    {
        id: 4,
        imgSrc: brand_img_4
    },
    {
        id: 5,
        imgSrc: brand_img_5
    },
    {
        id: 6,
        imgSrc: brand_img_6
    },
    {
        id: 7,
        imgSrc: brand_img_7
    },
    {
        id: 8,
        imgSrc: brand_img_8
    },
    {
        id: 9,
        imgSrc: brand_img_9
    },
    {
        id: 10,
        imgSrc: brand_img_10
    }
]

