import safetyMainImg from "../../assets/safetyAd/safetyMain.png";
import addImg from "../../assets/safetyAd/ad.png";
import circleImg from "../../assets/safetyAd/circle.png";

import ProductSliderComponent from "../productSlider/productSlider.component";
import ReviewComponent from "../review/review.component";
import "./safetyAd.style.scss";

const SafetyAdComponent = () => {
  return (
    <>
      <div className="SafetyAd_container">
        <p className="Heading">
          Authorized Dealer of Zain Safety: Ensuring the Safety Needs
        </p>
        <div className="Details_wrapper">
          <div className="Left_side_wrapper">
            <div className="Main_img">
              <img src={safetyMainImg} alt="safetyMainImg.png" />
            </div>
            <div className="Ads_img_wrapper">
              <div className="Ads_img_inner_wrapper">
                <img className="Ads_img" src={addImg} alt="addImg.png" />
                <img
                  className="Circle_img"
                  src={circleImg}
                  alt="circleImg.png"
                />
              </div>
            </div>
          </div>
          <div className="Right_side_wrapper">
            <p className="Long_text">
              As an authorized Zain Safety dealer,{" "}
              <b>
                we offer a wide range of high-quality safety products tailored
                to meet your industrial needs.
              </b>
              From personal protective equipment to safety gear and accessories,
              Zain Safety is synonymous with reliability and performance. Rest
              assured, our genuine Zain Safety products ensure top-notch safety
              standards, providing you peace of mind. Explore our collection
              today and prioritize safety in your workplace with Zain Safety.
            </p>
            <button>Explore Now</button>
          </div>
        </div>
      </div>
      <ProductSliderComponent />
      <ReviewComponent />
    </>
  );
};

export default SafetyAdComponent;
