import banner_img_1 from "../../../assets/bannerImages/banner_img_1.png";
import banner_img_2 from "../../../assets/bannerImages/banner_img_2.png";
import banner_img_3 from "../../../assets/bannerImages/banner_img_3.png";
import banner_img_4 from "../../../assets/bannerImages/banner_img_4.jpg";
import banner_img_5 from "../../../assets/bannerImages/banner_img_5.png";
import banner_img_6 from "../../../assets/bannerImages/banner_img_6.png";
import banner_img_7 from "../../../assets/bannerImages/banner_img_7.png";


export const bannerData = [
    {
        id: 1,
        imgSrc: banner_img_1
    },
    {
        id: 2,
        imgSrc: banner_img_2
    },
    {
        id: 3,
        imgSrc: banner_img_3
    },
    {
        id: 4,
        imgSrc: banner_img_4
    },
    {
        id: 5,
        imgSrc: banner_img_5
    },
    {
        id: 6,
        imgSrc: banner_img_6
    },
    {
        id: 7,
        imgSrc: banner_img_7
    }
]