import React from "react";
import Slider from "react-slick";
import slider_img_1 from "../../assets/productSliderImages/slider_img_1.png";
import slider_img_2 from "../../assets/productSliderImages/slider_img_2.png";
import slider_img_3 from "../../assets/productSliderImages/slider_img_3.png";
import slider_img_4 from "../../assets/productSliderImages/slider_img_4.png";

import "./productSlider.style.scss";
import { arrowSVG } from "../../utils/svg";

const ProductSliderComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth <= 768 ? 1 : 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {arrowSVG()}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        {arrowSVG()}
      </div>
    );
  }

  return (
    <>
      <div className="Product_slider_container">
        <h1 className="Heading">Featured Products</h1>
        <div className="slider-container">
          <Slider {...settings}>
            <div className="Slider_img_wrapper">
              <div className="Img_wrapper">
                <img src={slider_img_1} alt="slider_img_1.png" />
              </div>
              <div className="Details_wrapper">
                <div className="Brand_name">3M</div>
                <div className="Product_title">Bump Cap</div>
              </div>
            </div>
            <div className="Slider_img_wrapper">
              <div className="Img_wrapper">
                <img src={slider_img_2} alt="slider_img_2.png" />
              </div>
              <div className="Details_wrapper">
                <div className="Brand_name">KARAM</div>
                <div className="Product_title">Hear Muff - High dB</div>
              </div>
            </div>
            <div className="Slider_img_wrapper">
              <div className="Img_wrapper">
                <img src={slider_img_3} alt="slider_img_3.png" />
              </div>
              <div className="Details_wrapper">
                <div className="Brand_name">JCB</div>
                <div className="Product_title">
                  Holton Honey Nubuck Safety Boot
                </div>
              </div>
            </div>
            <div className="Slider_img_wrapper">
              <div className="Img_wrapper">
                <img src={slider_img_4} alt="slider_img_4.png" />
              </div>
              <div className="Details_wrapper">
                <div className="Brand_name">ZAIN SAFETY</div>
                <div className="Product_title">
                  ZM Dexter 82333 Sporty Safety Shoe
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ProductSliderComponent;
