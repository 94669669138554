import ServicesComponent from '../services/services.component'
import './trustCount.style.scss'

const TrustCountComponent = () => {
    return(
        <>
            <div className="Trust_count_container">
                <div className="Quality_product Trust_wrapper">
                    <p className="Count">250+</p>
                    <p className="Text">Quality Products</p>
                </div>
                <span className='Line' />
                <div className="Trusted_brands Trust_wrapper">
                    <p className="Count">10+</p>
                    <p className="Text">Trusted Brands</p>
                </div>
                <span className='Line' />
                <div className="Happy_clients Trust_wrapper">
                    <p className="Count" Trust_wrapper>10+</p>
                    <p className="Text" Trust_wrapper>Happy Clients</p>
                </div>
                <span className='Line' />
                <div className="Client_satisfaction Trust_wrapper">
                    <p className="Count">100%</p>
                    <p className="Text">Client Satisfactions</p>
                </div>
            </div>
            <ServicesComponent />
        </>
        
    )
}

export default TrustCountComponent