import { Link } from "react-router-dom";

import logo from "../../../assets/logo.png";
import { menuIcon, backIcon } from "../../../utils/svg";
import "./mobile.header.style.scss";

const MobileHeader = () => {
  const headerNav = [
    {
      id: 1,
      nav: "Home",
      link: "/",
    },
    {
      id: 2,
      nav: "Construction Resources",
      link: "/construction resources",
    },
    {
      id: 3,
      nav: "Industrial Supplies",
      link: "/industrial supplies",
    },
    {
      id: 4,
      nav: "Contact Us",
      link: "/contactUs",
    },
  ];

  function openNav() {
    document.getElementById("mySidenav").style.width = "75%";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  const handleSideNav = () => {
    return (
      <div className="SideNav_wrapper">
        <div id="mySidenav" class="sidenav">
          <div className="Close-icon" onClick={closeNav}>
            {backIcon()}
          </div>
          <div className="Logo-wrapper" onClick={closeNav}>
            <Link to={`/`} className="Left_wrapper">
              <img className="Image" src={logo} alt="logo.png" loading="lazy" />
            </Link>
          </div>
          <div className="Link-wrapper" onClick={closeNav}>
            {headerNav?.map((res) => {
              return <Link to={res.link}>{res.nav}</Link>;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="Mobile-header-container">
      <div className="Header-Wrapper">
        <Link to={`/`} className="Left_wrapper">
          <img className="Image" src={logo} alt="logo.png" loading="lazy" />
        </Link>
        <div onClick={() => openNav()}>{menuIcon()}</div>
      </div>
      {handleSideNav()}
    </header>
  );
};

export default MobileHeader;
