import { Link } from "react-router-dom";

import menu_1 from "../../assets/megaMenuImages/menu_1.png";
import menu_2 from "../../assets/megaMenuImages/menu_2.png";
import menu_3 from "../../assets/megaMenuImages/menu_3.png";
import menu_4 from "../../assets/megaMenuImages/menu_4.png";
import menu_5 from "../../assets/megaMenuImages/menu_5.png";
import menu_6 from "../../assets/megaMenuImages/menu_6.png";
import menu_7 from "../../assets/megaMenuImages/menu_7.png";

import "./megaMenu.style.scss";

const MegaMenuComponent = () => {

    const menuData = [
        {
            id: 1,
            image: menu_1,
            title: 'Industrial Machinery'
        },
        {
            id: 2,
            image: menu_7,
            title: 'Safety Supplies'
        },
        {
            id: 3,
            image: menu_6,
            title: 'Oil & Gas'
        },
        {
            id: 4,
            image: menu_5,
            title: 'Electrical Equipments'
        },
        {
            id: 5,
            image: menu_4,
            title: 'Measurements & Testing'
        },
        {
            id: 6,
            image: menu_3,
            title: 'Home Appliances'
        },
        {
            id: 7,
            image: menu_2,
            title: 'Packaging'
        }
    ]
    return(
        <>
            <div className="Mega_menu_container">
                <div className="Inner_mega_menu">
                    <div className="Menu_wrapper">
                        {
                            menuData.map((data) => {
                                return(
                                    <Link to={`/industrial-supplies/${data.title}`} className="Content_wrapper">
                                        <img className="Menu_image" src={data.image} alt={`${data.title}.png`} />
                                        <p className="Heading">{data.title}</p>
                                    </Link>
                                    
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MegaMenuComponent;