import "./review.style.scss"

const ReviewComponent = () => {
  return (
    <div className="Review_container">
      <div className="Top_wrapper">
        <div className="Heading_wrapper">
          <div className="Small_heading">
            <span class="material-symbols-outlined">remove</span>
            <span className="Text">Testimonials</span>
          </div>
          <p className="Info">Read from Our Satisfied Clients</p>
        </div>
        <div className="Detail_wrapper">
          <p>
            Our clients words speak volumes about our commitment to quality and
            service. Read what some of them have to say about their experience
            with Fantom
          </p>
        </div>
      </div>
      <div className="Review_wrapper">
        <div className="Info_wrapper">
          <p className="Long_text">
            "Using FANTOM for our construction projects has been a game-changer.
            Their products have helped us improve efficiency and safety on
            site."
          </p>
          {/* <p className="Compony_name">
            <span class="material-symbols-outlined">fiber_manual_record</span>
            Sarah L, Company 1
          </p> */}
        </div>
        <div className="Info_wrapper">
          <p className="Long_text">
            "We been in the industry for years, and I can confidently say that
            FANTOM is the most reliable partner for industrial goods. Their
            range of products and services is exceptional."
          </p>
          {/* <p className="Compony_name">
            <span class="material-symbols-outlined">fiber_manual_record</span>
            Daniel H, Company 2
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
