import industrial_supplies_banner from "../../../assets/categoryBanner/industrial_supplies_banner.png";
import construction_resources_banner from "../../../assets/categoryBanner/construction_resources_banner.png";


const industrialSupplies = {
    bannerImage: industrial_supplies_banner,
    subCategory: true,
    productSlider: true,
    discoverFuture: false,
    bannerText: "Industrial Essentials for Every Need"
}

const constructionResources = {
    bannerImage: construction_resources_banner,
    subCategory: true,
    productSlider: false,
    discoverFuture: true,
    bannerText: "Essential Resources for your Construction Projects"
}

export const categoryMockData = {
    industrial_supplies: industrialSupplies,
    construction_resources: constructionResources
}