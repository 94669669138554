import { Link } from "react-router-dom";
import { categoriesList, productListData } from "./__mock__data";
import { withRouter } from "../../utils/withRouter";

import "./productList.style.scss";
import { useEffect } from "react";

const ProductListComponent = ({ router }) => {
  const {
    params: { category_name, product_list_name },
  } = router;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category_name]);
  
  const breadcrumbs = () => {
    return (
      <div className="Breadcrumbs_container">
        <nav className="Breadcrumbs_wrapper">
          <Link className="Root_page" to={"/"}>
            Home
          </Link>
          <span>/</span>
          <Link className="Root_page" to={`/${category_name.replace(/-/g, " ")}`}>
            {category_name.replace(/-/g, " ")}
          </Link>
          <span>/</span>
          <Link to={`/${category_name}/${product_list_name.replace(/-/g, " ")}`}>
            {product_list_name.replace(/-/g, " ")}
          </Link>
        </nav>
      </div>
    );
  };
  return (
    <div className="ProductList_container">
      {breadcrumbs()}
      <div className="ProductList_container_wrapper">
        <div className="Left_side">
          <p className="Heading">Categories:</p>
          <div className="List_wrapper">
            <ul>
              {categoriesList.map((res) => {
                return <li key={res.id}>{res.title}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="Right_side">
          <div className="Details_wrapper">
            <div className="Heading_wrapper">
              <p className="Heading">Safety Supplies</p>
              {/* <span className="Count">(240 Products)</span> */}
            </div>
            <p className="Info_text">
              Discover essential safety supplies for every industry. From
              protective gear to safety signage,{" "}
              <b>we have everything you need to keep your workplace safe.</b>
            </p>
          </div>
          <div className="Product_list_Container">
            <div className="Product_list_wrapper  ">
              {productListData.map((res) => {
                return (
                  <div className="Slider_img_wrapper">
                    <div className="Img_wrapper">
                      <img src={res.imgSrc} alt="slider_img_1.png" />
                    </div>
                    <div className="Details_wrapper">
                      <div className="Brand_name">{res.title}</div>
                      <div className="Product_title">{res.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProductListComponent);
