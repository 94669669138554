import Slider from "react-slick";
import TrustCountComponent from "../trustCount/trustCount.component";
import { bannerArrowSVG } from "../../utils/svg";

import "./banner.style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { bannerData } from "./__Mock__data";

const BannerComponent = () => {

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
          <div
            className={className}
            onClick={onClick}
          >{bannerArrowSVG()}</div>
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, currentSlide, onClick } = props;
        console.log(props)
        return (
          <div
            className={className}
            onClick={onClick}
            style={{
                disabled: currentSlide === 1 ? true : false
              }}
          >{bannerArrowSVG()}</div>
        );
      }

  const bannerText = () => {
    return (
      <div className="Banner_text_wrapper">
        <p className="Main_text">Your Gateway to Premium Industrial Supplies</p>
        <p className="Sub_text">
          From Safety Tools to Building Materials, We've Got You Covered
        </p>
        <button>Discover Now</button>
      </div>
    );
  };

  return (
    <>
      <div className="BannerSlider_container">
        <div className="BannerSlider_wrapper">
          <div id="react-slick">
            <Slider
              autoplay={true}
              autoplaySpeed={1500}
              dots={true}
              arrows={true}
              infinite={true}
              nextArrow ={<SampleNextArrow />} 
              prevArrow= {<SamplePrevArrow />}
            >
              {bannerData.map((res) => {
                return (
                  <div key={res.id} className="Image_wrapper">
                    <img src={res.imgSrc} alt={`banner_${res.id}.png`} loading="lazy" />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        {bannerText()}
      </div>
      <TrustCountComponent />
    </>
  );
};

export default BannerComponent;
