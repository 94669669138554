import { subCategoryMockData } from "./__Mock__data";

import "./subCategory.style.scss";

const SubCategoryComponent = ({ categoryName }) => {
  console.log(categoryName)
  return (
    <div className="Sub_category_container">
      <div className="Heading_wrapper">
        <p className="Heading">Sub Categories</p>
      </div>
      {subCategoryMockData[categoryName?.replace(/ +/g, "_").toLowerCase()]?.map((res) => {
        return (
          <div className="Image_wrapper">
            <img loading="lazy" src={res.imgSrc} alt={`${res.title}.png`} />
            <p className="Title">{res.title}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SubCategoryComponent;
