import { useEffect } from "react";
import BannerComponent from "../../components/banner/banner.component"


const HomeComponent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <>
            <BannerComponent />
        </>
    )
}

export default HomeComponent;