import React from "react";

import "./brandCarousel.style.scss";

import { brandBannerData } from "./__Mock__data";

const BrandCarouselComponent = () => {
  return (
    <div className="Brand_carousel_wrapper">
      <div class="marquee">
        <div class="marquee-content">
          <ul class="list-inline">
            {
              brandBannerData.map(res => {
                return(
                  <li key={res.id}>
                    <img src={res.imgSrc} alt={`brand_img_${res.id}.png`} loading="lazy" />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BrandCarouselComponent;
