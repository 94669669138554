import React, { Component } from "react";
import logo from "../../assets/logo.png";
import { whatsAppIcon } from "../../utils/svg";

import "./inProgress.style.scss";

class InProgress extends Component {
  InProgressText() {
    return (
      <div className="Text">
        <p className="Desktop-View">Enable desktop view</p>
        {/* <p className="In-Progress-Text">In Progress</p> */}
      </div>
    );
  }

  bannerLoader() {
    return (
      <div className="Card-Wrapper">
        {this.InProgressText()}
        <div className="Content">
          <p className="Content-Loader"></p>
        </div>
      </div>
    );
  }

  collectionLoader() {
    return <></>;
  }

  render() {
    return (
      <>
        <div className="InProgress-Container">
          <img className="Image" src={logo} alt="logo.png" loading="lazy" />
          {this.bannerLoader()}
          <div className="WhatsApp_container">
            <a
              className="WhatsApp_icon"
              href="https://web.whatsapp.com/send/?phone=+919380582067"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="Icon">{whatsAppIcon()}</i>
              Message us on WhastApp
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default InProgress;
