import { Link } from "react-router-dom";
import { useEffect } from "react";
import { withRouter } from "../../utils/withRouter";
import discover_img from "../../assets/categoryBanner/images/discover.png";

import ProductSliderComponent from "../../components/productSlider/productSlider.component";
import SubCategoryComponent from "../subCategory/subCategory.component";
import { categoryMockData } from "./__Mock__data";

import "./category.style.scss";


const CategoryComponent = ({ router }) => {
  const {
    params: { category_name },
  } = router;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category_name]);

  const categoryData =
    categoryMockData[category_name.replace(/ +/g, "_").toLowerCase()];

  const breadcrumbs = () => {
    return (
      <div className="Breadcrumbs_container">
        <nav className="Breadcrumbs_wrapper">
          <Link className="Root_page" to={"/"}>Home</Link>
          <span>/</span>
          <Link to={`/${category_name}`}>
            {category_name.replace(/ +/g, " ")}
          </Link>
        </nav>
      </div>
    );
  };

  const handleBannerText = () => {
    return(
      <div className="BannerText_wrapper">
        <p className="Text">{categoryData?.bannerText}</p>
        {/* <button>View All</button> */}
      </div>
    )
  }

  const handleDetailsText = () => {
    return (
      <>
        {category_name.replace(/ +/g, "_").toLowerCase() ===
          "construction_resources" && (
          <div className="Details_wrapper_text">
            <p className="Long_text">
              Explore our extensive collection of construction supplies,
              carefully curated to cater to every aspect of your project.
              Whether you're constructing a new building or renovating an
              existing one, our range includes everything from basic building
              materials to specialized tools and equipment.{}
              <b>
                With a focus on quality and reliability, our products are
                designed to meet the demands of modern construction projects.
              </b>
              Simplify your construction process and achieve superior results
              with our trusted supplies.
            </p>
          </div>
        )}
        {category_name.replace(/ +/g, "_").toLowerCase() ===
          "industrial_supplies" && (
          <div className="Details_wrapper_text">
            <p className="Long_text">
              Discover a comprehensive range of industrial supplies, <b>including
              tools, safety equipment, and materials, all designed to meet the
              diverse needs of modern industries. </b>Explore our collection to find
              the perfect solutions for your workplace.
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="Category_container">
      <div className="Banner_image">
        <img
          src={categoryData?.bannerImage}
          alt="industrial_supplies.png"
          loading="lazy"
        />
        {breadcrumbs()}
        {handleBannerText()}
      </div>

      {handleDetailsText()}

      {categoryData.subCategory && (
        <SubCategoryComponent categoryName={category_name} />
      )}

      {categoryData.discoverFuture && (
        <div className="Discover_future_container">
          <div className="Left_side">
            <img src={discover_img} alt="discover_img.png" loading="lazy" />
          </div>
          <div className="Right_side">
            <p className="Title">
              Discover the Future of Construction:{" "}
              <span className="Sub_text">Recraft Building</span>
            </p>
            <p className="Long_text">
              Embrace sustainable construction practices with our range of
              recraft building resources. From reclaimed materials to innovative
              eco-friendly products, we offer everything you need to build
              responsibly. Explore our collection to discover new possibilities
              for your construction projects and join us in shaping a greener
              future for the construction industry.
            </p>
          </div>
        </div>
      )}

      {categoryData.productSlider && <ProductSliderComponent />}
    </div>
  );
};

export default withRouter(CategoryComponent);
