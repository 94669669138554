import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import HeaderComponent from "../components/header/header.component";
import FooterComponent from "../components/footer/footer.component";
import HomeComponent from "./home/home.component";
import CategoryComponent from "./category/category.component";
import ContactUsComponent from "../components/contactUs/contactUs.component";

import { useLocation } from "react-router-dom"
import ProductListComponent from "./productList/productList.component";
import InProgress from "../components/inProgress/inProgress.component";
import MobileHeader from "../components/header/mobile/mobile.header.component";


const LayOut = () => {
  const { pathname } = useLocation()
  const skeleton = window.innerWidth <= 768;
  // if(skeleton) {
  //   return <InProgress />
  // }
  return (
    <>
      <MobileHeader />
      <HeaderComponent />
      <Outlet />
      {!pathname.includes('contactUs') &&
        <FooterComponent />
       }
    </>
  );
};

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <LayOut />,
    children: [
      {
        path: "/",
        element: <HomeComponent />,
      },
      {
        path: "/:category_name",
        element: <CategoryComponent />,
      },
      {
        path: "/contactUs",
        element: <ContactUsComponent />,
      },
      {
        path: "/:category_name/:product_list_name",
        element: <ProductListComponent />,
      }
    ],
  },
]);

export default AppRouter;