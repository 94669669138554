import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";
import MegaMenuComponent from "../megaMenu/megaMenu.component";

import "./header.style.scss";
import { downArrow, upArrow } from "../../utils/svg";

const HeaderComponent = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="Header_container">
        <div className="Header_wrapper">
          <Link to={`/`} className="Left_wrapper">
            <img className="Image" src={logo} alt="logo.png" loading="lazy" />
          </Link>
          <div className="Right_side">
            <Link
              to={`/${"construction resources"}`}
              className="Construction_wrapper"
            >
              <span className="Alert_text">New!</span>
              <span>Construction Resources</span>
              {/* <span
                  class="material-symbols-outlined"
                >
                  {downArrow()}

                </span> */}
            </Link>
            <div className="Industrial_wrapper">
              <Link
                to={`/${"industrial supplies"}`}
                className="Industrial_wrapper_link"
              >
                Industrial Supplies
              </Link>
              {isMenuOpen ? (
                <span
                  class="material-symbols-outlined"
                  onClick={() => setMenuOpen(!isMenuOpen)}
                >
                  {upArrow()}
                </span>
              ) : (
                <span
                  class="material-symbols-outlined"
                  onClick={() => setMenuOpen(!isMenuOpen)}
                >
                  {downArrow()}
                </span>
              )}
            </div>

            <Link to={`/${"contactUs"}`} className="ContactUs_wrapper">
              <button>Contact Us</button>
            </Link>
          </div>
        </div>
      </div>
      {isMenuOpen && <MegaMenuComponent />}
    </>
  );
};

export default HeaderComponent;
