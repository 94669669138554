import { emailSVG, locationSVG, phoneSVG, whatsAppIcon } from "../../utils/svg";
import FBIcon from "../../assets/social/FB.png";
import LinkedInIcon from "../../assets/social/linkedIn.png";
import InstagramIcon from "../../assets/social/Instagram.png";
import emailjs from 'emailjs-com';
import "./contactUs.style.scss";
import { useEffect, useState } from "react";

const ContactUsComponent = () => {

  let initialState = {
    from_name: "",
    organization: "",
    from_email: "",
    phone_number: "",
    message: "",
    requirement: ""
  };

  const SERVICE_ID = "service_06lm4hr"
  const TEMPLATE_ID = "template_d8l88yn"
  const PUBLIC_KEY = "qe_vx3CoMfGudv-pa"

  const [userInputs, setInputs] = useState(initialState);
  const [isSubmit, handleSubmit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleSubmit(!formButtonEnable(userInputs));
  }, [userInputs]);

  const formButtonEnable = (fieldValue) => {
    let empty = Object.values(fieldValue).map((elem) => {
      if (elem === "") {
        return false;
      }
    });
    return empty.includes(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputs({
      ...userInputs,
      [name]: value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        alert('Message Sent Successfully')
      }, (error) => {
        console.log(error.text);
        alert('Something went wrong!')
      });
    setInputs(initialState)
    // e.target.reset()
  };

  return (
    <div className="ContactUs_container">
      <from className="Contact_form_wrapper" onSubmit={handleOnSubmit}>
        <div className="Left_side">
          <div className="Text_wrapper">
            <p className="Text">
              Let’s begin your <span className="Sub_text">Fantom journey</span>{" "}
              now. Say Hello!{" "}
            </p>
            <p className="Description">
              Reach out to us for inquiries, support, or just to say hello. Our
              team is here to assist you with all your industrial needs.
            </p>
          </div>
        </div>
        <div className="Right_side">
          <div className="Form_wrapper">
            <div className="Contact-Container">
              <div className="Contact-wrapper">
                <div className="content">
                  <form>
                    <div className="field-group">
                      <label>
                        Name:
                        <input
                          type="text"
                          name="from_name"
                          value={userInputs.from_name}
                          onChange={handleOnChange}
                          placeholder="eg: Francis Xavier"
                        />
                      </label>
                      <label>
                        Organization:
                        <input
                          type="text"
                          name="organization"
                          value={userInputs.organization}
                          onChange={handleOnChange}
                          placeholder="eg: Company name"
                        />
                      </label>
                    </div>
                    <div className="field-group">
                      <label>
                        Email Address:
                        <input
                          type="email"
                          name="from_email"
                          value={userInputs.from_email}
                          onChange={handleOnChange}
                          placeholder="francisxavier97@gmail.com"
                        />
                      </label>
                      <label>
                        Phone Number:
                        <input
                          type="tel"
                          name="phone_number"
                          value={userInputs.phone_number}
                          onChange={handleOnChange}
                          placeholder="eg: +91 9876543210"
                          maxLength={10}
                        />
                      </label>
                    </div>
                    <label>
                      Requirement:
                      <input
                        type="text"
                        name="requirement"
                        value={userInputs.requirement}
                        onChange={handleOnChange}
                        placeholder="eg: Safety supplies, packaging etc..."
                      />
                    </label>
                    <label>
                      Message:
                      <textarea
                        name="message"
                        value={userInputs.message}
                        onChange={handleOnChange}
                        placeholder="Write your message here or Say Hello..."
                      />
                    </label>
                    <button className={isSubmit ? 'submit' : 'submit-disable'} type="submit" disabled={!isSubmit} >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </from>
      <div className="Address_container">
        <div className="Location_wrapper">
          <div>{locationSVG()}</div>
          <p className="Heading">Our Office:</p>
          <p className="Text">
            229/S1, 3rd Cross Brindavan extension, Mico Layout, Arekere,
            Bengaluru - 560078
          </p>
        </div>
        <div className="Email_wrapper">
          <div>{emailSVG()}</div>
          <p className="Heading">Email Us:</p>
          <p className="Text">info.fantom1@gmail.com, contact@fantomenterprises.com</p>
        </div>
        <div className="Phone_wrapper">
          <div>{phoneSVG()}</div>
          <p className="Heading">Call Us:</p>
          <p className="Text">9380582067 | 7975352621</p>
        </div>
      </div>
      <div className="FollowUs_container">
        <div className="Social_wrapper">
          <p className="Title">Follow us:</p>
          <div className="Image_wrapper">
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=61559868220545&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FBIcon} alt="FBIcon.png" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/enterprisesfantom/?igsh=MWN1eDJua3Fkdmticw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="Instagram_img"
                  src={InstagramIcon}
                  alt="InstagramIcon.png"
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/in/fantom-enterprises-257b1a2b6?original_referer="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedInIcon} alt="LinkedInIcon.png" />
              </a>
            </div>

            <div className="WhatsApp_container_mob">
              <a
                className="WhatsApp_icon"
                href="https://web.whatsapp.com/send/?phone=+919380582067"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="Icon">{whatsAppIcon()}</i>
              </a>
            </div>
          </div>
        </div>
        <div className="WhatsApp_container">
          <a
            className="WhatsApp_icon"
            href="https://web.whatsapp.com/send/?phone=+919380582067"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="Icon">{whatsAppIcon()}</i>
            Message us on WhastApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComponent;
