import img_1 from "../../../assets/subCategoryImages/constructionResources/img_1.png";
import img_2 from "../../../assets/subCategoryImages/constructionResources/img_2.png";
import img_3 from "../../../assets/subCategoryImages/constructionResources/img_3.png";
import img_4 from "../../../assets/subCategoryImages/constructionResources/img_4.png";
import img_5 from "../../../assets/subCategoryImages/constructionResources/img_5.png";
import img_6 from "../../../assets/subCategoryImages/constructionResources/img_6.png";
import img_7 from "../../../assets/subCategoryImages/constructionResources/img_7.png";

import IS_img_1 from "../../../assets/subCategoryImages/industrialSupplies/img_1.png";
import IS_img_2 from "../../../assets/subCategoryImages/industrialSupplies/img_2.png";
import IS_img_3 from "../../../assets/subCategoryImages/industrialSupplies/img_3.png";
import IS_img_4 from "../../../assets/subCategoryImages/industrialSupplies/img_4.png";
import IS_img_5 from "../../../assets/subCategoryImages/industrialSupplies/img_5.png";
import IS_img_6 from "../../../assets/subCategoryImages/industrialSupplies/img_6.png";
import IS_img_7 from "../../../assets/subCategoryImages/industrialSupplies/img_7.png";

const constructionResourcesData = [
    {
      id: 1,
      imgSrc: img_1,
      title: "Cement",
    },
    {
      id: 2,
      imgSrc: img_4,
      title: "Cleaning Equipments",
    },
    {
      id: 3,
      imgSrc: img_6,
      title: "Fabrications",
    },
    {
      id: 4,
      imgSrc: img_3,
      title: "False Ceiling",
    },
    {
      id: 5,
      imgSrc: img_2,
      title: "Glass",
    },
    {
      id: 6,
      imgSrc: img_5,
      title: "Gypsum",
    },
    {
      id: 7,
      imgSrc: img_7,
      title: "TMT Rods",
    },
  ];

  const industrialSuppliesData = [
    {
      id: 1,
      imgSrc: IS_img_1,
      title: "Industrial Machinery",
    },
    {
      id: 2,
      imgSrc: IS_img_2,
      title: "Safety Supplies",
    },
    {
      id: 3,
      imgSrc: IS_img_3,
      title: "Oil & Gas",
    },
    {
      id: 4,
      imgSrc: IS_img_4,
      title: "Electrical Equipments",
    },
    {
      id: 5,
      imgSrc: IS_img_5,
      title: "Measurements & Testing",
    },
    {
      id: 6,
      imgSrc: IS_img_6,
      title: "Home Appliances",
    },
    {
      id: 7,
      imgSrc: IS_img_7,
      title: "Packaging",
    },
  ];

export const subCategoryMockData = {
  industrial_supplies: industrialSuppliesData,
  construction_resources: constructionResourcesData
}



